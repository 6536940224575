$break-xs: 480px;
$break-s: 768px;
$break-m: 992px;
$break-lg: 1200px;
.divider {
  display: none;
  @media screen and (min-width: $break-xs) and (max-width: $break-lg) {
    display: block;
  }
}
.search-icon {
  position: absolute;
  top: 19px;
  right: -3px;
}
.search-icon-container {
  position: relative;
  width: max-content;
  height: max-content;
}
.pageName {
  font-weight: bold;
  font-size: 18px;
}

.admin-home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__discovery_btn {
    background-color: #2d809f;
  }
}

.auth {
  font-size: 24px;
  color: #2c809f;
  font-weight: 700;
}

.templateLink {
  white-space: nowrap;
  a {
    color: $darkBlue;
    font-weight: 600;
  }
  .fake-link{
    color: $darkBlue;
    font-weight: 600;
    cursor:pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}
.list-title{
  font-size: 30px;
}
.colTitle {
  font-weight: 700;
  font-size: 20px;
}
