.bottom-border {
  display:block;
  border:none;
  color:white;
  height:1px;
  background:black;
  background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 600, from($midGrey), to(#fff0));
  margin-top: 4rem;
}

.button-container-indicator-select {
  display: flex;

  &__input-set {
    margin-left: 8px;
    // display: flex;
    // justify-content: space-between;
    // width: 25%;
  }
}

.content-indicator-select-form {
  flex-direction: row;
  justify-content: space-evenly;
}


.indicator-group-create-survey {
    text-align: center;
}

.indicator-headers {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
}

.indicator-subgroups-create-survey {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;

    &__block {
      display: block;
      margin-bottom: 16px;
      // width: 45%;
    }
}

.subgroup-type-header {
  text-transform: capitalize;

  &__sub-head {
    padding: 8px 16px;
    background-color: #10526a;
    display: flex;
    // justify-content: space-between;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}

.indicator-options {
  padding: 16px;
  background: #fff;
}

.subgroup-create-survey {
  &:first-of-type {
    margin-right: 4rem;
  }

  &:last-of-type {
    margin-right: 2rem;
  }

  p {
    &:first-child {
      font-weight: bold;
      font-size: 1.3rem;
      color: #fff;
      background-color: #10526a;
      padding: 4px;
    }
  }

  &.other {
    background-color: transparent;
  }
  background-color: #fff;
  // padding: 0 ;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
