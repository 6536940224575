
// @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
.list-organisations {
  display: block;
  width: 100%;
  padding: 0 16px;

}
.list-organisation {
  // padding: 0 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;

  &__static {
    font-size: large;
    color: #10526a;
    font-weight: 600;
    text-decoration: underline;

    @media (max-width: 1200px) {
      font-size: 10px;
    }
    @media (max-width: 800px) {
      font-size: 8px;
    }

    &__caret {
      margin-left: 16px;
      color: gray;
    }
  }
  
  &__sub-header {
    margin-bottom: 16px;

    &__buttons {
      float: right;
    }
  }

  &__filters {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      display: grid;
    }
    
    &--isSchoolChamp {
      @media (max-width: 600px) {
        display: grid !important;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
        display: flex;
        align-items: center;
      }
    }

    >div {
      margin-right: 8px;
    }
  }

  .table {
    border: 1px solid lightgray;
  }
}

.page-item.active .page-link {
    background-color: $darkBlue;
    border-color: $darkBlue;
}

.page-link {
  color: $darkBlue;
}
