/** Generic Card **/

.tm-card {
  $padding-sm: 26px;
  $padding-md: 56px;
  $padding-lg: 66px;

  background-color: #fff;
  max-width: 854px;
  width: 100%;

  .tm-card-header {
    padding: 26px;
    p {
      font-size: 15px;
      line-height: 1.5rem;
    }
  }

  .tm-card-content {
    display: flex;
    flex-direction: column;
    padding: $padding-md;
  }

  &.tm-card-sm {
    .tm-card-header {
      padding: $padding-sm $padding-sm 10px;
    }
    .tm-card-content {
      padding: 10px $padding-sm $padding-sm;
    }
  }

  &.tm-card-md {
    .tm-card-header {
      padding: $padding-md $padding-md 10px;
    }
    .tm-card-content {
      padding: 10px $padding-md $padding-md;
    }
  }

  &.tm-card-lg {
    .tm-card-header {
      padding: $padding-lg $padding-lg 10px;
    }
    .tm-card-content {
      padding: 10px $padding-lg $padding-lg;
    }
  }

  // If no header is passed. Adjust padding
  &.tm-card-headerless {
    &.tm-card-sm {
      padding: $padding-sm;
    }
    &.tm-card-md {
      padding: $padding-md;
    }
    &.tm-card-lg {
      padding: $padding-lg;
    }
  }
}

// Generalized Stepper Card
// Fix padding, height etc.
.tm-card.tm-card-stepper {
  margin-left: auto;
  margin-right: auto;

  .tm-card-header {
    img {
      max-width: 420px;
      width: 90%;
      min-width: 240px;
      margin-bottom: 30px;
    }
    max-width: 600px;
    margin: 0 auto;
  }

  .tm-card-content {
    max-width: 600px;
    margin: 0 auto;

    .form-group {
      width: 100%;
      max-width: 371px;
      margin: 0 auto;

      input {
        margin-bottom: 10px;
      }
    } // end form-group

    button.tm-button {
      // max-width: 300px;
      width: 100%;
      margin: 40px auto 10px;
    }
  }
}
