.header-container-survey-list {
  display: flex;
  margin-top: 4rem;
}

.survey-list-button-header-container {
  float: right;
}
.survey-list-container {
  max-height: 80vh;
  width: 100%;
}