//because we're pulling in bootstrap css (not scss) we cannot overwrite variables
//just following their pattern
$spacer: 1rem !default;

.mb-6 {
  margin-bottom: $spacer * 3.5 !important;
}

$font-size-sm: 16px;
$font-size-md: 24px;
$font-size-lg: 1.1rem;

$mq-min-767: "only screen and (max-width: 767.95px)";
