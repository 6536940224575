$maxLayoutWidth: 1400px;
$headerFooterHeight: 360px;

.offcanvas-backdrop {
  height: 100%;
  width: -webkit-fill-available;
}
.offcanvas-body {
  background-color: #10526a;
  color: #fff;
 
  .sidebar {
    &__nav-items__sub-link {
      color: #fff;
      text-decoration: none;
    }

    &__nav-items__li {
      display: flex;
      align-items: center;
      margin: 16px 0;

      svg {
        margin-right: 4px;
      }
    }
  }
}
.sub-header {
  float: right;
}
.sidebar{
  &__nav-items {
    @media (max-width: 600px) {
      font-size: 8px;
    }
  }
  &__copyright {
    bottom: 0;
    position: absolute;
    margin-bottom: 8px;
    @media (max-width: 600px) {
      font-size: 8px;
  }}

}
.tm-layout {
  height: 100%;
  background-color: #f8f9fc;
  width: 100%;

  .open-sans-tm {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }
  > .container{
    max-width: $maxLayoutWidth;
    min-height: calc(100vh - #{$headerFooterHeight});
  }
  header > .container {
    max-width: 100%;
  }

  &__page {
    padding: 20px;
  }

  &__flex {
    display: flex;
    height: 100%;

    &__canvas-icon {
      display: flex;
      align-items: center;
      position: absolute;
      right: -4px;
      height: 75%;
      

      @media (max-width: 1000px) {
        height: 50%;
      }

      svg {
        background-color: #10526a;
        border-radius: 2px;
        padding: 3px;
        cursor: pointer;
        box-shadow: 0 0 8px #75bbd5;

      }

      &__burger {
        display: flex;
        align-items: center;
        height: 75%;
        position: absolute;
        right: -4px;

        @media (max-width: 1000px) {
          height: 50%;
         }

        svg {
          background-color: #10526a;
          border-radius: 2px;
          padding: 2px;
          cursor: pointer;
          box-shadow: 0 0 8px #75bbd5;
          transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
        }
      }

      &__fa {
        color: #fff !important;

        &--disabled {
          color: gray;
        }
      }
    }
  }

  .container-main {
    width: 100%;
    height: 100%;
  }

  .container-main--expanded {
    width: 100%;
    height: 100%;
  }
  .main-page-center {
    margin: auto;
    min-height: 90%;
    background-color: #f8f9fc;
    display: flex;
    overflow-y: scroll;

    &__nav-buttons {
      display: flex;
      width: 100%;
      justify-content: center;

      margin: 8px 16px;
      overflow: auto
    }
  }


  .sidebar {
    width: 260px !important;
    height: 100%;
    background-color: #10526a !important;
    color: #fff !important;
    position: relative;
    transition: all 0.3s ease-in-out !important;
    border: 1px solid #10526a !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;

    @media (max-width: 1000px) {
      width: 200px !important;

      ul {
        padding: 8px;
      }
     }

    &__copyright {
      font-size: smaller;
      position: fixed;
      bottom: 0;
      margin-bottom: 16px;
    }

    &__nav-items {
      width: 100%;

      @media (max-width: 600px) {
        font-size: 8px;
      }

      ul {
        padding-left: 16px;
      }
      
      &__li {
        display: flex;
        align-items: center;
        padding-left: 8px;
        border-radius: 2px;
      }

      .nav-link {
        padding: 8px;
      }

      &__link {
        color: #fff;
        font-weight: 600;
        padding: 8px;
        font-size: 12px;
      }

      &__sub-link {
        color: #fff;
        text-decoration: none;
        padding: 8px !important;
        border-radius: 2px;

        &--disabled {
          color: gray;
          padding: 8px !important;
        }

        &__active {
          background-color: #2c6f88;
        } 
      }
    }
  } 

  .sidebar-mobile {
    position: relative;
    height: 100%;
    width: 16px !important;
    
  }

  .sidebar-sm {
    position: relative;
    width: 80px !important;
    height: 100%;
    background-color: #10526a !important;
    color: #fff !important;
    transition: all 0.3s ease-in-out !important;
    border: 1px solid #10526a !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .main-nav-buttons {
    background-color: white;
      flex-flow: column;
      border-radius: 8px;
      box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
      padding: 16px;
      outline: none;
      transition: all 0.1s ease-in-out;
      border: 1px solid lightgray;
      align-items: center;
      height: 300px;
      width: 100%;
      justify-content: center;

      .icon {
        display: flex;
        justify-content: center;
      }
  }

  .nav {
    background-color: white;
    flex-flow: column;
    border-radius: 8px;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
    padding: 16px;
    outline: none;
    transition: all 0.1s ease-in-out;
    border: 1px solid lightgray;
    align-items: center;

     @media (max-width: 600px) {
      width: 300px;
    }
  }
}

.tm-layout.opacity-50 {
  pointer-events: none;
}

.container-fluid {
  padding: 0px;
  margin-bottom: 8px;

  &__filters {
    padding: 8px 0;
    margin: 0;
    // border: 1px solid #e3e3e3 !important;
    // border-radius: 2px !important
  }

  &__filter__flex {
    margin-right: 8px;
  }
}
// Adjust survey contain to take up available height
.survey-container {
  main {
    min-height: calc(100vh - #{$headerFooterHeight});
  }
}
