@media print {
	// Force a white background when in print mode..
	// Note this is global.
	body {
		background-color: #fff;
		padding: 0 0 50px;
    zoom: 70%;

		.intro-hero.mb-5 {
			margin-bottom: 1rem !important;
		}
    
		.tm-report {
			header {
				padding: 1rem;
				.print-logo {
					margin: 1rem 0 0 auto;
					width: auto;
				}
			}
		}

		.tm-report .page,
		.tm-report.tm-report-parent .page {
			padding: 50px;
			page-break-inside: avoid;

			&:last-of-type {
				padding-bottom: 0;
			}

			p {
				line-height: 1.5;
			}
		}
	}

	.page-break {
		page-break-after: always;
	}

	.print-block {
		page-break-inside: avoid;
		padding: 1rem 0;
	}

	.image-fit {
		max-height: 145px;
		object-fit: cover;
	}

	@import '../utils/print-grid';
}

@page {
	size: auto; /* auto is the initial value */
	margin: 0mm; /* this affects the margin in the printer settings */
}

// The Report Wrapper for both Parent and Student Reports
// This does not cover school reports

.tm-report {
	$headerHeight: 70px;
	background-color: #fff;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	label,
	p {
		margin: 0;
		padding: 0;
	}

	h2 {
		font-size: 15px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.03rem;
	}

	h6 {
		font-size: 12px;
	}

	p {
		font-size: 0.8rem;
	}

	header {
		background-color: #eee;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		padding: 0px;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: space-between;

		h1 {
			font-size: 1.4rem;
			span {
				font-weight: normal;
				font-size: 1rem;
			}
		}

		section {
			display: flex;
			flex-direction: column;
			padding: 16px;
			justify-content: center;

			&.priority {
				background-color: rgba(0, 0, 0, 0.12);
				h1 {
					font-size: 1.7rem;
					font-weight: 300;
				}
			}

			&.branding {
				img {
					height: 30px;
				}
				.print {
					line-height: 1.2;
					height: 2rem;
					min-width: 6rem;
				}
			}
		} // end header section
	} // end header

	.wellness-center-home-header {
		padding: 0 40px;
		max-width: 1280px;
		margin: 50px auto 0 auto;
	}
	.accordion div.card:only-child {
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
		border-radius: calc(0.25rem - 1px);
	}

	.page {
		max-width: 1280px;
		margin: 0px auto;
		padding: 40px;

		@at-root {
			.tm-report.tm-report-parent .page {
				padding-left: 15px;
				padding-right: 15px;

				@media only screen and (min-width: 768px) {
					padding-left: 40px;
					padding-right: 40px;
				}
			}
		}

		.page-footer {
			width: 100%;
		}

		&.page-parent-report {
			padding-top: 25px;
		}

		&.page-parent-report .indicator-suggestions {
			padding: 15px 5px 25px;
			font-size: 0.8rem;

			p,
			li {
				font-size: 0.8rem;
			}
		}
    
		&.page-report-results {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			main {
				flex-grow: 1;
				flex-shrink: 1;
				// width: calc(70% - 50px);
			}
		}
	}

  .report-font-size{
      font-size:1rem;
      *{
        font-size:1rem;
      }
    }

	.block {
		h3 {
			font-size: 0.9rem;
			line-height: 0.9rem;
			font-weight: 700;
			border-bottom: solid 1px #fff;
			margin-bottom: 16px;
			padding-bottom: 10px;
			display: inline-block;
		}

		p {
			font-size: 0.7rem;
			line-height: 0.9rem;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 6px;
			}
		}
	}

	.block.score {
		font-size: 1rem;
		padding: 4px 10px;
		vertical-align: middle;
	}

	.justify-space-between {
		justify-content: space-between;
	}

	.previous-score {
		display: flex;
		align-items: center;
		color: rgba(0, 0, 0, 0.4);

		.block {
			border: none;
		}

		a {
			display: none !important;
		}
	}

	.table.results {
		font-size: 0.8rem;

		td {
			vertical-align: middle;
		}

		th {
			background-color: #eee;
			text-transform: uppercase;
			font-size: 0.76rem;
			line-height: 1.1rem;
      color: black;
		}

		.score {
			text-align: center;
			padding: 4px;
			font-size: 0.8rem;
			font-weight: bold;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			a {
				height: 16px;
				line-height: 14px;
				padding: 0 6px;
				font-size: 0.5rem;
				margin-left: 6px;
				color: #fff;
				opacity: 1;
				transition: all 0.2s ease-in-out;

				&.show-link {
					font-size: inherit;
				}
			}
		}

		&.table-50-50 {
			td,
			th {
				width: 50%;
			}
		}
		&.table-1-3 {
			td,
			th {
				width: calc(100% / 3);
			}
		}
	}

	.tfs-bar {
		height: $headerHeight;
		display: flex;
		padding: 0px;
		align-items: center;
		background-color: #eee;

		label {
			display: flex;
			height: 100%;
			align-items: center;
			padding: 0 16px;
			background-color: rgba(0, 0, 0, 0.1);
		}

		.value {
			flex-grow: 1;
			text-align: center;
			text-transform: uppercase;
			font-weight: 700;
		}

		&.tfs-bar-previous {
			height: $headerHeight * 0.5;
		}
	}
	.page.questions-of-concern {
		h1 {
			font-size: 26px;
		}
	}
	.concerns-container {
		.answers {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.qa {
				font-size: 0.8rem;
				margin-bottom: 18px;
				width: 50%;

				div {
					display: flex;
					flex-direction: row;
					padding: 3px 2px;

					.label {
						display: inline-block;
						width: 100px;
					}

					.index {
						width: 20px;
						display: inline-block;
					}
				}
			}
		}
	}

	// Font Sizes
	.font-size-xs {
		font-size: 0.8rem;
	}

	.font-size-sm {
		font-size: 0.9rem;
	}

	.font-size-md {
		font-size: 1rem;
	}

	.font-size-lg {
		font-size: 1.2rem;
	}

	.font-size-xl {
		font-size: 1.4rem;
	}
} // end tm-report

.block {
	&.block-outline-red {
		border: solid 1px $lightRed;
		color: $lightRed;
	}

	&.block-red {
		background-color: $lightRed;
		color: #fff;
	}

	&.block-outline-grey {
		border: solid 1px $midGrey;
		color: $midGrey;
	}

	&.block-grey {
		background-color: $midGrey;
		color: #fff;
	}

	&.block-outline-green {
		border: solid 1px $darkGreen;
		color: $darkGreen;
	}

	&.block-green {
		background-color: $darkGreen;
		color: #fff;
	}

	&.block-outline-light-green {
		border: solid 1px $lightGreen;
		color: $lightGreen;
	}

	&.block-light-green {
		background-color: $lightGreen;
		color: #fff;
	}

	&.block-outline-yellow {
		border: solid 1px $mustard;
		color: $mustard;
	}

	&.block-yellow {
		background-color: $mustard;
		color: #fff;
	}
}

.score a {
	display: block;
	color: #fff;
	text-decoration: underline;
	font-weight: bold;

	&::after {
		content: '›';
		height: 1em;
		width: 1em;
		margin-left: 0.25em;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 50%;
		display: inline-block;
		transition: all 0.2s ease-out;
		font-size: 15px;
		line-height: 1;
		font-weight: bold;
		text-decoration: none;
	}

	&:hover {
		text-decoration: none;

		&::after {
			transform: translate(4px, 0);
		}
	}
}

.show-not-concerning-responses {
	align-self: center;

	.checkbox {
		display: inline-block;
	}
}
