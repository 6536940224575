.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 30px;
  width: 600px;
  max-height:600px;
}
.headingContainer {
  color: $darkGrey;
  max-width: 500px;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
}
.logo {
  // margin-bottom: 50px;
  width: 200px;
}
