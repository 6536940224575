.add-student-button {
  margin-right: 0.5rem;
  cursor:pointer;
}

.custom-file {
  position: relative;

  .error {
    position: absolute;
    top: 100%;
  }
}

.custom-file-label {
  border-radius: 0.25rem 1.25rem 1.25rem 0.25rem !important;
}

.custom-file-label::after {
  width: 8rem;
  text-align: center;
  color: white;
  border-radius: 0 1.25rem 1.25rem 0 !important;
  background-color: $lightBlue;
}

.file-input-create-survey-step-group {
  display: flex;
  align-items: center;
  .input-group {
    width: 24rem;
    margin-left: 2rem;
  }

  .input-group-student-roster {
    width: 100%;

    &__input {
      padding: 20px;
      border: 1px dotted #10526a;
    }

    &__button-upload {
      background-color: transparent;
      border:1px dotted #10526a;
      border-radius: 2px;
      color: #10526a;
      margin-top: 40px;
    }
  }
}
.yellow {
  color: $mustard;
  font-style: italic
}

.email-recipient-step{
  display: flex;
  flex-direction: column;
}

.manual-student {
  margin-left: 5rem;
  margin-top: 2rem;
}

.manual-student-header {
  font-weight: bold;
  margin-bottom: 0;
}

.manual-student-subhead {
  margin-bottom: 3rem;
}
