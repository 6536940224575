.footnote-text-edit-participant {
  font-size: 0.7rem;
  &:first-of-type {
    margin-top: 1rem;
  }
}

.header-survey-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &:last-of-type {
    margin-bottom: 1rem;
  }
}
.modal-header-edit-participant {
  border-bottom: 2px solid rgb(52, 91, 128);
  margin-bottom: 20px;
}

.edit-participant-form {
  padding: 16px;

  &__fields {
    display: block
  }
  .info {
    margin-left: 4px;
    cursor: pointer;
    color: rgb(116, 179, 231);
  }
}

.student-results-label-edit-participant {
  font-size: 0.9rem;
}

.green-badge {
  background-color: #738065 !important;
}

.survey-info{
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // grid-gap: 10px;
  // gap: 10px;
  background: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin-bottom: 8px;

  div {
    &:first-child {
      margin-right: 16px;
    }
  }

  &__empty {
    font-size: 16px;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #377b9d;
  }
}

.context-menu {
  margin-top: 14px;
  display: inline-flex;
  line-height: 5px;
  border: 1px solid black;
  border-radius: 3rem;
  width: 3rem;
  height: 20px;
  text-align: center;
  padding-left: 11px;
}

.actions-menu {
  display: flex;
  justify-content: flex-end;
  gap: $spacer * .5;
  align-self: flex-start;
}

.dropdown-content {
  display: none;
  position: absolute;
  // margin-top: 5px;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4);
  z-index: 1;
  right: 0;
  float: right;
}

.dropdown-content span {
  color: $midBlue;
  padding: 8px 5px;
  font-size: 1rem;
  text-decoration: none;
  display: block;
}

.dropdown-content span:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
  float: right;
}

.grade-label {
  color: gray;
  padding: 0;
  font-size: .9rem;
}

@media print {
  header,
  footer,
  .tableHeader,
  .create-survey-container .table td:nth-child(n+5) {
    display: none !important;
  }

  .create-survey-container .table td {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .create-survey-container .table td:nth-child(2):before {
    content: 'Grade:';
    margin-right: 0.5rem;
  }

  .create-survey-container .table td:nth-child(3):before {
    content: 'Date of Birth:';
    margin-right: 0.5rem;
  }

  .create-survey-container .table td:nth-child(4):before {
    content: 'Login:';
    margin-right: 0.5rem;
  }
}

.create-survey-container {
  margin: 16px;
  height: 80vh;
  overflow-y: auto;
  // overflow: visible;
  width: 100%;

  &__indicators-list {
    max-height: 140px;
    overflow-y: auto;
  }

  .sub-header__create_assessment {
    font-size: 20px;
    font-weight: bold;
  }
  &__buttons-flex{
    display: flex;
    float: right;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
}

.report-link {
  display: inline-block;

  .report-icon {
    display: block;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      display: block;
    }
    &[data-contacted=true] {
      margin-right: 30px;
    }
    
    &[data-contacted=true]:before {
      content: '✓';
      top: 8px;
      right: -20px;
      height: 8px;
      width: 8px;
      font-size: 16px;
      font-weight: 700;
      line-height: 0.65;
      color: $darkGreen;
    }

    &[data-num]:after {
      content: attr(data-num);
      border: 4px solid white;
      border-radius: 50%;
      right: -9px;
      bottom: -4px;
      z-index: 1;
      height: 22px;
      width: 22px;
      color: white;
      font-weight: 900;
      font-size: 11px;
      text-align: center;
    }

    &[data-num='1']:after,
    &[data-num='2']:after {
      background-color: $red;
    }

    &[data-num='3']:after,
    &[data-num='4']:after,
    &[data-num='5']:after {
      background-color: $mustard;
    }
  }
}

.statusCountText{
  color: $darkGrey;
  font-size: $font-size-sm;
}

.modal-table-container{
  max-height:500px;
  overflow-y:scroll;
}

.select-all-invite {
  align-items: center;
  display: flex;
  justify-content: left;
}

.surveyDetailsDropDownButton{
  background-color: $darkGreen;
  border: $darkGreen;

  border-radius: 2px;
  margin-left: 8px;
  width: 128px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.surveyDetailsDropDownButton:hover,.surveyDetailsDropDownButton:focus,.surveyDetailsDropDownButton:active{
  background-color: $darkestGreen;
  border: $darkestGreen;
}

.field-select-wrapper  span.selectDescription { display: none;}
.field-select-wrapper:hover span.selectDescription { display: unset;}