.breadcrumb-create-survey {
  background-color: #ff000000;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-inline-start: 0;
}

.pointer{
  cursor:pointer;
}
.bold-normal{
  font-weight: 400;
}
.breadcrumb-info-container {
  p {
    margin-top: 0;
    margin-bottom: 0rem;
    font-size: 0.8rem;
    color: grey;
  }
}

.dob-container-participant-entry {
  display: flex;
  align-items: flex-end;
  margin-left: 1rem;
}

.rw-i-caret-down:before {
  content: '\E803';
  color: $midBlue;
}

.rw-widget-input {
  border: 1px solid #CACACA;
  height: 40px !important;
  box-shadow: none !important;
  border-radius: 2px !important;
}

// General Drop Down Styles go here
.tm-dropdown {
  width: 300px !important;
  border: 1px solid #CACACA;

  &--required {
    border: 1px solid red;
    border-radius: 2px;
  }

  &--filed {
    border: 1px solid #CACACA;
  }

  .rw-widget-input{
    border-width: 0;
  }
}

.tm-dropdown.short {
  width: 8rem !important;
}

.tm-dropdown.wide {
  width: 18rem !important;
}

.modal {
  &.edit-participant-modal-wrapper {
    .modal-dialog {
      @media (min-width: 576px) {
        max-width: 700px;
        width: 700px;
      }
    }
  }

  .add-participant-modal-wrapper {

    &__buttons-wrap {
      display: flex;
      justify-content: space-between;

      .button {
        width: 400px;
      }
    }
    .modal-dialog {
      @media (min-width: 576px) {
        max-width: 820px;
        width: 820px;
      }
    }
  }
}
.add-participant-modal-wrapper {
  &__buttons-wrap {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    &__button {
      background-color: #10526a !important;
      color: #fff !important;
      border-radius: 3px;
      margin: 8px;
    }
  }
}
.participant-row {
  // display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  max-width: 100%;
}

.label-field-container {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 16px;
  border-radius: 2px;

  &.phone {
    max-width: 146px;
  }

  &.icon {
    max-width: 48px;
  }

  &:last-child {
    margin-right: 0;
  }

  .tm-dropdown.short {
    margin-right: 0 !important;
    width: auto;
    border-radius: 2px;

    .form-inputs {
      width: 72px !important;
    }
  }
}

.label-background-color {
  width:auto;
  border-radius: 10px;
}

.uk-date-order {
  display: flex;

  .uk-date-order-0 {
    order: 0;
  }

  .uk-date-order-1 {
    order: 1;
  }

  .uk-date-order-2 {
    order: 2;
  }
}

.form-container-create-survey {
  display: flex;
  flex-direction: row;
  margin-left: 5rem;


  .dropdown-container {
    margin-right: 16px;
    // Specific drop down styles here like width
    .rw-dropdown-list {
      width: 150px;
    }
  }
}

.form-group {
  margin-right: 1rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.participant-entry-field {
  margin: 0 !important;
  height: 40px !important;
  border-radius: 2px;
  min-width: 300px;
  width: 300px;

  &--filed {
    border: 1px solid rgb(204, 204, 204);
  }

  &--required {
    border: 1px solid red !important;
  }

  @extend .rw-widget-input;
    padding-left: 1rem;
    height: 3rem;
}

.participant-entry-field.long {
  width: 20rem;
}

.participant-entry-field.short {
  width: 8rem;
}
.tag {
  margin-top: 1rem;
}
.tag-item {
  background-color: #bcbcbc;
  display: inline-block;
  font-size: 14px;
  border-radius: .4rem;
  height: 30px;
  padding: 0 5px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0.1rem 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: rgb(255, 255, 255);
  width: 22px;
  height: 22px;
  border-radius: .4rem;
  border: none;
  cursor: pointer;
  font: inherit;
  margin:0 0 0 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-header {
  display: flex;
  .step-number {
    font-size: 40px;
    font-weight: bold;
    margin-right: 2rem;
    margin-bottom: 0;
    line-height: 1;
  }
  div {
    p {
      &:first-of-type {
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.survey-margin-left {
  margin-left: 5rem;
}

.survey-step-container {

  &__copy-button {
    background-color: #10526a;
    color: #ffffff;
    width: 180px;
    height: 40px;
    border-radius: 6px;

  }

  margin: 8px 0px;
  &:not(:last-child){
    &:after {
      content: '';
      display:block;
      // border:none;
      color:white;
      // height:1px;
      background:black;
      background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 600, from($midGrey), to(#fff0));
      margin-top: 32px;
    }
  }

  &:last-child {
    padding-bottom: 150px;
  }

  &[data-active=false] {
    pointer-events: none;
    opacity: 0.5;
  }
}

.clever-student-selection-wrapper {
  max-width: 600px;
  background: $lightMidGrey;
  padding: 25px;
}

.clever-all-grades-wrapper {
  margin-bottom: 15px;
}

.clever-student-selection {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex-basis: 25%;
    margin-bottom: 15px;
  }
}
