// @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.alert {
  margin-top: 40px;
  color: white !important;
  background-color: $lightBlue !important;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem !important;
}

.alertHeader {
  font-weight: bold;
  font-size: 1.2rem;
}

.breadcrumbButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.breadcrumbLink {
  color: black;
  text-decoration: underline;
}

.btnContainer {
  display: flex;
  margin-top: 1rem;
}

.content {
  padding: 8px 24px;
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalContent {
  padding: 2rem;
}

.modalBtn {
  width: auto;
  border-radius: 4px !important;
  border: 1px solid #0b0b0b;
}

.modalInput {
  width: 100%;
  background-color: $inputFill;
  padding: 15px;
  border: none;
  border-radius: 5px;
  margin: 4px 0 20px;
}

.table {
  [data-key="icon"],
  [data-key="star"] {
    width: 1px;
    white-space: nowrap;
  }

  thead th {
    background-color: $darkBlue;
    color: $white;
    text-transform:uppercase;
  }
}

.tm-table {
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border: 1px solid #e4e0e0;
}

.tableHeader {
  color: $white;
  font-size: 0.8rem;

  &__calendar {
    margin-left: 4px;
    cursor: pointer;
  }
}

Table {
  background-color: white;
  border: 1px solid rgb(189, 187, 187);
}

.tmHeader {
  font-size: 0.8rem;
  color: grey;
}
