.form-control {
  //padding: 25px 10px;
  height: 40px;
  // border: none;
  border-radius: 3px;
  margin: 16px 0;

  &:focus {
    outline: none;
    box-shadow: none;
    background-color: $inputFill;
  }
  &.has-error {
    border: solid 1px $red;
  }
}
.input::placeholder {
  color: #bababa;
}

textarea.form-control { 
  height: unset;
}

.label {
  margin-bottom: 0;
  font-weight: bold;
}

.change-password,
.reset-password {

  .breadcrumb {
    padding-top: 0;
  }

  .form-group {
    align-items: flex-start;
    width: auto;
    margin-right: 0;
  }
}

.react-tel-input {
  input[type=text],
  input[type=tel] {
    height: 40px !important;
    border-radius: 2px;

    &.participant-entry-field {
      background-color: transparent ;

      input :required {
        border: 1px solid #fd9d9d;
      }
    }
  }

  .participant-entry-field + .flag-dropdown {
    border: none;
  }
}

.search-participants-form {
  border: 1px solid #bababa;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;

  @media (max-width: 600px) {
    max-width: fit-content;
  }

  &__fields {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
      display: block;
     }
  }

  &__input {
    margin: 8px 0px!important;
    width: 200px;
    height: 40px;
    border: 1px solid rgb(228, 226, 226);
    border-radius: 3px;
  }
}



.rw-widget-container{
  border-width: 0px;
  border-radius: 2px;

}

.rw-widget-container.selected{
  border-width: 0px;
  border-radius: 2px;
}

#consentEditForm {
  textarea {
    height: unset;
  }
}