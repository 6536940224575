$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(('100': $gray-100,
      '200': $gray-200,
      '300': $gray-300,
      '400': $gray-400,
      '500': $gray-500,
      '600': $gray-600,
      '700': $gray-700,
      '800': $gray-800,
      '900': $gray-900,
    ),
    $grays
);

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

// Breadcrumbs
$breadcrumb-item-padding: 0.5rem !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote('>') !default;
$breadcrumb-border-radius: $border-radius !default;

.flex {
  display: flex;
  align-items: center;
  // justify-content: space-between;

  &__homebutton {
    margin-left: auto;
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $breadcrumb-border-radius;
  margin: 8px 0;

  .breadcrumb-item {
    padding: 0px 24px;
    background: white;
    text-align: center;
    display: flex;
    align-items: center;
    min-height: 50px;
    position: relative;

    &:nth-of-type(2)::before,
    &:nth-of-type(3)::before {
      content: '' !important;
    }
  }
}

.breadcrumb-item:after,
.breadcrumb-item:before {
  background: white;
  bottom: 0;
  clip-path: polygon(50% 50%, -50% -50%, 0 100%);
  content: "";
  left: 100%;
  position: absolute;
  top: 0;
  width: 1em;
  z-index: 1;
  
}

.breadcrumb-item:before {
  background: #abc094;
  margin-left: 2px;
}

.breadcrumb-item.active::before,
.breadcrumb-item.active::after {
  background: #fff !important;
  margin-left: 0px;
}

.float-right {
  float: right;
}