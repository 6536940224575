.container-search-reports {
  margin-bottom: 2rem;
}

.input-container-search-reports {
  display: flex;
  width: 70rem;
  margin: 16px;
}

.radio-container-search-reports {
  margin:0 2rem !important;
}

.radio-search-reports {
  margin-top: 1rem;
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-gap: 10px;
  // gap: 10px;

  div {
    margin-right: 1rem;
    align-items: baseline;
    input {
      margin-right: 1rem;
    }
  }
}
