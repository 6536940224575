 .checkbox {
   margin-right: 8px;
   height: 16px;
   width: 16px;
   border-radius: 2px;
   border: 1px solid gray;
 }

 .checkbox {
   display: block;
   cursor: pointer;
   position: relative;

   &__input {
    margin-right: 8px;
   }
 }