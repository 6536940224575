$break-xs: 480px;
$break-s: 768px;
$break-m: 992px;
$break-lg: 1200px;
$footer-color: white;

footer {
    // bottom: 0;
    // position: absolute;
    // width: 100%;

  .smallfooter {
    width: 100%;
    background-color: $footer-color;
    height: 100px;
    display: flex;
    flex-flow: row;
    align-content: center;
   
    @media (max-width: 1000px) {
      display: none;
    }
   
    img {
      margin: auto 7px;
      width: 50px;
      height: 50px;
      @media screen and (max-width: $break-s) {
        display: none;
      }
    }
    div {
      display: flex;
      flex-flow: column;
      align-content: center;
      justify-content: space-around;
      margin: 5px 0;
      h6,
      p {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  .footerUserInfo {
    // bottom: 0;
    // right: 50px;
    // position: absolute;
    // z-index: 1;
    ul {
      list-style-type: none;
      text-align: left;
      justify-content: space-between;
      flex-flow: column;
      margin-bottom: 0 !important;
      li {
        a {
          color: $darkBlue;
          img {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
          &:visited {
            color: $darkBlue;
          }
        }
      }
    }
  } // end .smallFooter

  .largefooter {
   
     @media screen and (max-width: $break-s) {
      display: none;
    }

    // .right {
    //   height: 30vw;
    //   max-height: 175px;
    //   position: absolute;
    //   width: 100%;
    //   bottom: 0;
    //   pointer-events: none;
    //   @media screen and (max-width: $break-s) {
    //     display: none;
    //   }
    //   @media screen and (min-width: $break-m) {
    //     height: 175px;
    //     bottom: 0;
    //   }
    // }

    // .left {
    //   position: absolute;
    //   width: 30vw;
    //   height: 15vw;
    //   background: $footer-color;
    //   bottom: 0;
    //   display: flex;
    //   justify-content: center;

    //   @media screen and (max-width: $break-s) {
    //     display: none;
    //   }
    //   @media screen and (min-width: $break-m) {
    //     max-height: 150px;
    //   }

    //   .content {
    //     width: 100%;
    //     position: relative;
    //     display: flex;
    //     flex-flow: row;
    //     justify-content: center;
    //     align-content: center;
    //     margin: 45px auto 0;
    //     background-color: #fff;

    //     .logo_footer {
    //       height: 100px !important;
    //       width: 100px !important;
    //     }

    //     @media screen and (min-width: $break-m) and (max-width: $break-lg) {
    //       transform: scale(0.8);
    //     }

    //     @media screen and (max-width: $break-m) {
    //       transform: scale(0.7);
    //     }

    //     img {
    //       margin-right: 20px;
    //       width: 55px;
    //       height: 55px;
    //     }
    //     div {
    //       h6,
    //       p {
    //         font-size: 16px;
    //         margin: 0;
    //         white-space: nowrap;
    //       }
    //     }
    //   }
    // }
  } // end .largeFooter

  .logo_footer {
          height: 50px !important;
          width: 50px !important;
          margin-right: 16px;
    }

  .left:before {
    content: '';
    position: absolute;
    border-left: 30.1vw solid transparent;
    border-bottom: 20px solid $footer-color;
    border-right: 15px solid transparent;
    transform: skew(-36.5deg);
    top: -20px;
  }
  .left:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-right: 25px solid transparent;
    border-bottom: calc(15vw + 20px) solid $footer-color;
    top: -20px;
    right: -25px;
    @media screen and (min-width: $break-m) {
      border-bottom: 170px solid $footer-color;
    }
  }
} // end footer

