.header {
  box-sizing: border-box;
  min-height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 4px 4px -4px rgb(157, 159, 160);
  border-bottom: 1px solid #e9e9e9;

  @media (max-width: 800px) {
    width: auto;
  }
  
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;

    .org_logo {
      padding-right: 2em;
      small {
        font-variant-caps: all-small-caps;
      }
    }
    
    .branding {
      flex-basis: 246px;
      flex-grow: 0;
      flex-shrink: 0;

      @media (max-width: 1000px) {
        flex-basis: 48px;
      }
      &__logo {
        height: 40px;
        width: 40px;
      }

      &__logo-web {
        height: 60px;
        // width: 246px;

        @media (max-width: 1000px) {
          width: 180px;
          height: 46px;
        }
      }
  }
  }

  .logo {
    width: auto;
    height: 7 * 8px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .user {
    display: flex;
    text-align: right;
    font-size: 0.8rem;
    align-items: center;
    // width: 100%;
    // justify-content: end;
    margin-left: auto;

    @media (max-width: 600px) {
      justify-content: start;
    }

    .logout {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }

    .username {
      font-weight: bold;
      color: $darkGrey;
    }

    .role {
      color: rgba(0, 0, 0, 0.7);
      font-style: italic;
    }

    a {
      color: $darkBlue;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .avatar {
      height: 50px;
      width: 50px;
      // margin-left: 20px;

      @media (max-width: 1000px) {
        height: 20px;
        width: 20px;
      }
    }
  } // end .user
} // end header
