// [data-role="admin"] uses default colors

$roles: (
  district_champion: (
    theme-color: $darkGreen,
    theme-color-muted: $darkestGreen
  ),
  school_champion: (
    theme-color: $midRed,
    theme-color-muted: $darkRed
  )
);

// Themed elements
@each $role, $content in $roles {
  [data-role="#{$role}"] {
    .themed-fill {
      fill: map-get($content, 'theme-color') !important;

      &.muted {
        fill: map-get($content, 'theme-color-muted') !important;
      }
    }
    .themed-stroke {
      stroke: map-get($content, 'theme-color') !important;

      &.muted {
        stroke: map-get($content, 'theme-color-muted') !important;
      }
    }
  }
}
