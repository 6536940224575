.toast {
  &.tm {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    margin: 0;
    padding: 1.5rem 1.75rem;
    min-width: 250px;
    color: #fff;
    z-index: 99999;

    .close {
      position: absolute;
      top: 8px;
      right: 8px;
      font-weight: 100;
      text-shadow: none;
      color: #000;
    }

    .toast-body {
      padding: 0;
    }
  }
}
