.page {
  margin: auto;

  @media (max-width: 1000px) {
    margin: 0;
  }
}

.small-blue-button {
  background-color: $lightBlue !important;
  height: 40px;
  font-size: 12pt;
  min-width: 128px;
  color: white;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  border-radius: 2px;
  border: none;
  margin-left: 8px !important;

  &:hover {
    background-color: $midBlue !important;
    color: $white;
    transition: background-color 300ms ease;
  }
}

.small-blue-button-outlined {
  background-color: $transparent;
  height: 40px;
  font-size: 12pt;
  width: 138px;
  // min-width: 10rem;
  color: $midBlue;
  transition: background-color 300ms ease;
  border-radius: 2px;
  border: 0.08rem solid $midBlue !important;
  margin-left: 8px !important;
  margin-right: 8px;

  &:hover {
    background-color: $midBlue;
    color: $white;
    transition: background-color 300ms ease;
  }
}

.small-red-button-outlined {
  background-color: $transparent;
  height: 50px;
  font-size: 12pt;
  width: 10rem;
  color: red;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: 0.08rem solid red !important;
  margin-right: 1rem;

  &:hover {
    background-color: red;
    color: $white;
    transition: background-color 300ms ease;
  }
}

.small-gray-button-outlined {
  background-color: $transparent;
  height: 50px;
  font-size: 20pt;
  font-weight: bold;
  width: 9rem;
  color: gray;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: 0.08rem solid gray !important;
  margin-right: 1rem;

  &:hover {
    background-color: $transparent;
    color: $midBlue;
    transition: background-color 300ms ease;
    border: 0.08rem solid $midBlue !important;
    cursor: pointer;
  }
}

.login-form {
  button {
    width: 300px;
  }
}

.button {
  border-radius: 50px;
  font-weight: bold;
  font-size: 13pt;
  cursor: pointer;
  border: none;
  margin-top: 25px;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

.green {
  // width: 300px;
  background-color: $lightGreen;
  color: white;
  transition: background-color 300ms ease;
  height: 75px;
  &:hover {
    background-color: $darkerGreen;
    color: white;
  }
  &:hover > div > div > img {
    transform: translateX(10px);
  }
  div {
    div {
      img {
        transition: transform 1s ease;
      }
    }
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.3rem rgba($darkerGreen, 0.25);
  }
  //TODO: Make active green not b
  &[disabled] {
    background-color: #72c563;
    &:hover {
      background-color: #198753;
    }
  }
}

.disabled,
[disabled] {
  opacity: 0.5;
  cursor: no-drop;
  &:hover > div > img {
    transform: none;
  }
}

.xtra-small-button {
  height: 30px;
  font-size: 12pt;
  line-height: 12pt;
  width: auto;
  min-width: 6rem;
  color: white;
  background-color: #95a880;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: none;
  margin-right: 1rem;
}

.small-button {
  height: 40px;
  font-size: 12pt;
  min-width: 138px;
  color: white;
  background-color: $darkGreen;
  transition: background-color 300ms ease !important;
  border-radius: 2px;
  border: none;

  &--trasnparent {
    background-color: transparent;
    color: black;
    border-radius: 2px;
  }

  &:hover {
    background-color: $darkerGreen !important;
  }

  &__margin-left {
    margin-left: 0 !important;
  }
}

.small-button-toggle {
  height: 50px;
  font-size: 12pt;
  width: 10rem;
  color: white;
  background-color: $midGrey;
  transition: background-color 300ms ease;
  border-radius: 2px;
  border: none;
  margin-right: 1rem;

  &:hover {
    background-color: #8aa690 !important;
  }

  &.active {
    background-color: #8aa690 !important;
  }
}

.impersonate-btn {
  background-color: #2c809f;
  border-radius: 2px;
  margin-right: 8px;
  border-radius: 3px;
  border: 1px solid #2c809f;

  &:hover {
    background-color: #1a5064 !important;
  }
}

.nav-buttons {
  margin: 0 !important;

  .col-sm-3 {
    margin-bottom: 8px;
  }

  @media (max-width: 1000px) {
    margin: 8px !important;
  }
}

.navigation-tab {
  border-bottom: 1px solid #10526a;

  :hover {
    color: #10526a;
  }
  .nav-item {
    cursor: pointer;

    .nav-link.active {
      border-top: 1px solid #10526a;
      border-right: 1px solid #10526a;
      border-left: 1px solid #10526a;
      border-bottom: 1px solid #fff;
    }
  }
  li {
    a {
      color: #10526a;
      :hover {
        color: #10526a;
      }
    }
  }
}

.nav {
  // background-color: white;
  // display: flex;
  // flex-flow: column;
  // align-items: center;
  // border-radius: 8px;
  // box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
  // padding: 16px;
  // outline: none;
  // height: 300px;
  // width: 100%;
  // transition: all 0.1s ease-in-out;
  // border: 1px solid lightgray;

  &:active {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: white;
      color: rgba(0, 0, 0, 0.6);
    }
    &:active {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    }
  }

  .icon {
    height: 150px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    svg {
      max-width: 100px;
      max-height: 100px;
    }
  }

  .desc {
    font-size: 16px;
    line-height: 22px;
  }

  .mainText {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 4px;
    // text-transform: uppercase;
  }
}

.button-main {
  background: $midBlue;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    text-decoration: none;
    background: $midBlue;
    color: #fff;
  }
}

.button-back {
  background: transparent;
  border: none;
  color: $midBlue;
  cursor: pointer;
  border-radius: 2px;
  // width: 262px;

  &:hover {
    text-decoration: none;
    background: transparent;
    color: $midBlue;
  }

  svg {
    margin-right: 4px;
  }
}

.button-text {
  background: transparent;
  border: none;
  color: $midBlue;
  cursor: pointer;

  &.no-link {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}
