.container-search-survey {
  margin-bottom: 2rem;

  &__reports {
    display: flex;
    justify-content: space-between;
    margin: 8px 16px;
    align-items: center;
  }
}

.input-container-search-survey {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: 1250px) {
      width: 300px;
    }

    @media (max-width: 950px) {
      width: 230px;
    }

    @media (max-width: 599px) {
      width: 280px;
    }

    @media (max-width: 500px) {
      width: 230px;
    }
    
}

.label {
  display: block;
  font-size: 14px;
  
  >input {
    margin-left: 4px;
  }
}
.search-survey {
  &__dropdown {
    float: left !important;
    margin-top: 4px;

    .dropdown-toggle {
      background-color: transparent;
      height: 40px;
      border: 1px solid #275d77;
      border-radius: 2px;
      color: #275d77;
      width: 112px;
    }

    .dropdown-menu {
      border-radius: 3px;
    }
  }
}

.search-survey-header {
  font-weight: bold;
  font-size: 14px;
  margin: 0 8px 0 0;
  line-height: 16px;
}
.search-survey-title {
  font-size: 14px;
  margin-bottom: 4px;
}
.search-survey-action {
  // padding: 8px !important;

  &__button {
    margin-right: 8px;
  }
}

.assessment-table {
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
.pagination {
  margin-left: 16px;
}
.search-survey-input {
    border: none;
    color: #333;
    font-size: 1em;
    font-family: inherit;
    outline: none;
    height: 40px;
    width: 18rem;
    border-radius: 0.4rem;
    box-shadow: 0px 1px 2px 0px rgba(150, 150, 150, 0.7);
    padding: 24px;
    margin-top: 8px;
}

.select__menu {
  z-index: 2 !important;
}

.search-survey-input-select {
  width: 350px !important;
}

.search-survey-submit-container {
  margin-top: 2rem;
}
