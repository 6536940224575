.forgot {
  color: black;
  margin-top: 4vh;
}

.logo-login {
  margin-bottom: 20px;
  width: 300px;
}

.clever-logo-login {
  margin-top: 20px;
  width: 300px;
}
.login-page-main {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  
}
.pageContainer {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #fff;
  height: 500px;
  box-shadow: #7599a5 0px 7px 29px 0px;
  border-radius: 4px;
  justify-content: center;

  @media (max-width: 700px) {
    height: 100vh;
    width: 100%;
   }

  &__login-image {
    height: 500px;

    @media (max-width: 700px) {
     display: none;
    }
  }

  &__login-fields {
    padding: 40px;
  }
}

.error {
  margin: 10px 0;
  display: block;
  font-size: 15px;
  font-style: italic;
  color: #f00;
}

.modalInput + .error {
  margin: -10px 0 10px;
}

.forgotPassword {
  font-size: 14px;
  color: gray;
  color: $darkBlue;
  margin-top: 30px;
  border: none;
}
